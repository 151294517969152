<template>
  <div class="d-flex justify-center align-center" style="height: 100%">
    <v-card width="500" class="mx-auto mt-8">
      <template #header>
        <v-card-title>Skift kodeord</v-card-title>
      </template>
      <v-window :value="passwordReset" vertical>
        <v-window-item :value="false">
          <v-form v-model="validPasswordReset" class="pa-4" @submit.prevent="resetPassword">
            <v-col cols="12">
              <div class="mb-6">Indtast din email og dit ønskede kodeord.</div>
              <v-text-field v-model="username" outlined dense label="Email" />
              <v-text-field
                outlined
                v-model="newPassword"
                label="Kodeord"
                dense
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              />
              <v-text-field
                outlined
                v-model="newPassword2"
                label="Gentag kodeord"
                dense
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, mustMatch]"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @keypress.enter="resetPassword()"
              />
              <v-alert v-if="error" class="error white--text" icon="mdi-alert"> Det skete en fejl, kodeord er ikke blevet skiftet </v-alert>
              <v-btn class="mt-4" depressed @click="resetPassword" :loading="isLoading" color="success" block :disabled="!validPasswordReset">
                Skift kodeord
              </v-btn>
            </v-col>
          </v-form>
        </v-window-item>
        <v-window-item :value="true">
          <div class="pa-8">
            <div class="text-h5 mb-4">Kodeord</div>
            <p>{{ checkIsStaff() }}</p>
          </div>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>

<script>
import apiService from '@/services/apiService.js';

export default {
  name: 'ResetPassword',
  data: () => ({
    isLoading: false,
    validPasswordReset: true,
    passwordReset: false,
    showPassword: false,
    username: null,
    newPassword: null,
    newPassword2: null,
    resetResult: null,
    error: null,
    rules: {
      required: (value) => !!value || 'Skal indeholde værdi.',
    },
  }),
  methods: {
    checkIsStaff() {
      return this.resetResult?.isStaff ? 'Dit kodeord er blevet skiftet og du er blevet logget ind.' : 'Dit kodeord er blevet skiftet';
    },
    mustMatch(value) {
      return value === this.newPassword || 'Kodeord er ikke identiske';
    },
    async resetPassword() {
      if (this.validPasswordReset) {
        this.isLoading = true;

        try {
          this.resetResult = await apiService.resetPasswordToken({
            username: this.username,
            token: this.$route.query.token,
            password: this.newPassword,
          });

          if (this.resetResult.isStaff) {
            await this.$store.dispatch('AUTHENTICATE', { userName: this.username, password: this.newPassword });
          }

          this.newPassword = null;
          this.newPassword2 = null;
          this.passwordReset = true;
        } catch (e) {
          this.error = e.message;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style></style>
